<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <section id="blog" class="blog" style="margin-top: 130px;">
    <div *ngIf="boton === 1" style="margin-bottom: 15px; justify-content: center; display: flex;">
      <button class="subir" style="border-radius: 1vh; width: 180px;" (click)="subirDocumento()">Subir Documento <i
          class="bi bi-cloud-arrow-up"></i></button>
    </div>
    <div class="fondo2">
      <div id="scroll">
        <div class="panel-group" id="accordion">

          <div class="panel panel-default">
            <div class="panel-heading">- Iniciativas y Proyectos
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897ms" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897ms" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589n" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589n" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1s"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1s" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911v" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911v" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sd"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sd" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911s" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911s" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msd" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msd" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mf" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mf" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(80,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ng" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ng" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="panel panel-default">
            <div class="panel-heading">- Ley de Ingresos y Presupuestos de Egresos
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1y"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1y" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911y" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911y" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897my" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897my" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ny" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ny" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sy"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sy" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sd"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sd" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911sy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911sy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(81,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngy" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngy" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="panel panel-default">
            <div class="panel-heading">-  Información Contable
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1ys"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1ys" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911ys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911ys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sys"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sys" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sds"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sds" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911sys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911sys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(82,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>






          <div class="panel panel-default">
            <div class="panel-heading">-  Información Presupuestaria
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1ysvS"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1ysvS" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911ysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911ysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sysv"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sysv" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sdsvG"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sdsvG" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911sys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911sys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngysv" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngysv" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div class="panel panel-default">
            <div class="panel-heading">- Inventarios
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1ysvp"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1ysvp" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911ysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911ysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sysvp"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sysvp" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(83,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sdsvp"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sdsvp" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911sysp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911sysp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngysvp" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngysvp" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>






          <div class="panel panel-default">
            <div class="panel-heading">-  Ejercicio Presupuestario
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1ysvpx"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1ysvpx" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911ysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911ysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sysvpx"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sysvpx" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vys" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vys" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(84,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sdsvpx"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sdsvpx" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911syspx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911syspx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(85,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngysvpx" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngysvpx" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="panel panel-default">
            <div class="panel-heading">- Cuenta Pública
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1ysvpxn"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1ysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911ysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911ysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sysvpxn"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vysn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vysn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sdsvpxn"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sdsvpxn" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911syspxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911syspxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(86,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngysvpxn" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngysvpxn" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div class="panel panel-default">
            <div class="panel-heading">- Disciplina Financiera
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1ysvpxnb"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2022</i></button>
              </h4>
            </div>
            <div id="collapse1ysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,201,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911ysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911ysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,202,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,203,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,204,2022)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sysvpxnb"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2023</i></button>
              </h4>
            </div>
            <div id="collapse1sysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,201,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911vysnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911vysnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,202,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msvysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msvysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,203,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mvysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mvysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,204,2023)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589nvysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589nvysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading">
              <h4 class="panel-title">
                <button data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapse1sdsvpxnb"
                  style="background: none; border: none;">
                  <i class="bi bi-folder-fill"> 2024</i></button>
              </h4>
            </div>
            <div id="collapse1sdsvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordion">
              <div class="panel-group" id="accordionc1" style=" margin-left: 20px;">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,201,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897m911syspxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Primer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897m911syspxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,202,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897msdysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Segundo Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897msdysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,203,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse24897mfysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Tercer Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse24897mfysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <button (click)="verDocumentos(87,204,2024)" data-bs-toggle="collapse" data-bs-parent="#accordionc1"
                        href="#collapse21589ngysvpxnb" style="background: none; border: none;">
                        <i class="bi bi-folder-fill"> Cuarto Trimestre</i></button>
                    </h4>
                  </div>
                  <div id="collapse21589ngysvpxnb" class="panel-collapse collapse in" data-bs-parent="#accordionc1">
                    <div class="panel-body">
                      <div class="fondo">
                        <div id="scroll">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"><img src="assets/img/file.png" alt="" height="30" width="30"> Nombre
                                  del
                                  documento</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let dato of docs, let i = index">
                                <td>
                                  <img src="assets/img/docs.png" alt="" height="30" width="30">
                                  <a [href]="dato?.ENLACE" target="_blank" style="text-align: end;">
                                    {{dato?.NOMBRE}}</a>
                                </td>
                                <td>
                                  <button *ngIf="boton === 1" (click)="eliminar(dato?.ID)" type="button"
                                    class="btn btn-outline-dark">Eliminar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>
</body>

</html>
